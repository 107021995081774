import React, { Fragment, useState, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useForm from 'react-hook-form'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { PanelRefreshIcons, SubmitButton } from '../../common/GlobalButton';
import { userGetMethod, userPostMethod } from '../../../api/userAction';
import { CLIENT_STOCK_RSURL, GET_CLIENT_STOCK_JOB_RSURL, JOB_ORDER_DETAILS, JOB_ORDER_RSURL, UNKNOWN_STOCK_RSURL } from '../../../api/userUrl';
import SweetAlert from 'sweetalert2';
import { placeHolderText } from '../../common/GlobalComponent';
import { trStyleNormal } from '../jobAgreement/Create';
import moment from 'moment';

const Add = (props) => {
    const { handleSubmit, register, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [dropdownData, setDropdownData] = useState({});
    const [typeheadOptions, setTypeheadOptions] = useState({});
    const [clientStockDetails, setClientStockDetails] = useState([]);
    const [selectedValue,setSelectedValue] = useState([]);
    const [clientText,setClientText] = useState('');
    const [typeAheadValue, setTypeAheadValue] = useState({
        'reference_job': [],
        'job_sub_class_id': [],
        'client_id': [],
        'printer_id': [],
        'marketing_person_id': [],
        'design_machine_id': []
    
      });

    let [jobOrderData, setJobOrderData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            job_name: '',
            job_no: '',
            printer_name: '',
            job_type: '',
            fl: '',
            cir: '',
            surface_area:'',
            total_surface_area: '',
            job_order_id: '',
            client_name: '',
            id: '',
            entry_date: '',
            agreement_date: '',
            total_cylinder_qty: '',
            client_id: '',
            printer_id: '',
            job_order_qty_limit: 0,
            orderQty: 0,
            fl:'',
            cir:'',
            dia:'',
            qty:'',
            receive_date : moment().format('YYYY-MM-DD')
        }
    );
    let job_order_id = props.location.state.params.job_order_id ? props.location.state.params.job_order_id : null;
    useEffect(() => {
        setIsLoading(true);
        // pageRefreshHandler(job_order_id);
        userGetMethod(`${JOB_ORDER_RSURL}/create`)
          .then(response => {
            // FOR MARKETING PERSON
            // console.log(response);
            let marketingEmpOptions = [];
            if (response.data.marketingPersons && response.data.marketingPersons.length > 0) {
              response.data.marketingPersons.map(person => {
                let personObj = {};
                personObj.id = person.id;
                personObj.name = `[${person.employee_id}] ` + person.name;
                marketingEmpOptions.push(personObj);
              })
            }
    
            // FOR PRINTERS
            let printerOptions = [];
            if (response.data.printers && response.data.printers.length > 0) {
              response.data.printers.map(printer => {
                let printerObj = {};
                printerObj.id = printer.id;
                printerObj.name = `[${printer.printer_code}] ${printer.printer_name}`;
                printerOptions.push(printerObj);
              })
            }
            // console.log(jobOrderType);
    
            // FOR CLIENTS
            let clientOptions = [];
            if (response.data.clients && response.data.clients.length > 0) {
              response.data.clients.map(client => {
                let clientObj = {};
                let name = client.name;
                clientObj.id = client.id;
                clientObj.name = `[${client.client_id}] ${name.slice(0,18)} /${client.address}`;
                clientOptions.push(clientObj);
              })
            }

            // FOR JOB SUB CLASS
            let subClassOptions = [];
            if (response.data.jobSubClasses && response.data.jobSubClasses.length > 0) {
              response.data.jobSubClasses.map(subClass => {
                let subClassObj = {};
                subClassObj.id = subClass.id;
                subClassObj.name = subClass.sub_class;
                subClassOptions.push(subClassObj);
              })
            }

            // // FOR REFERENCE JOB
            let referenceJobsOptions = [];
            if (response.data.referenceJobs && response.data.referenceJobs.length > 0) {
              response.data.referenceJobs.map(referenceJob => {
                let referenceJobObj = {};
                referenceJobObj.id = referenceJob.id;
                referenceJobObj.name = referenceJob.job_name;
                referenceJobsOptions.push(referenceJobObj);
              })
            }
    
            // FOR JOB SUB CLASS
            let additionalColorOptions = [];
            if (response.data.colors && response.data.colors.length > 0) {
              response.data.colors.map(color => {
                let colorObj = {};
                colorObj.id = color.id;
                colorObj.name = color.color_name;
                additionalColorOptions.push(colorObj);
              })
            }
    
            
            setTypeheadOptions(
              (prevstate) => ({
                ...prevstate,
                ['marketing_persons']: marketingEmpOptions,
                ['printers']: printerOptions,
                ['clients']: clientOptions,
                ['job_sub_classes']: subClassOptions,
                ['reference_jobs']: referenceJobsOptions,
                ['additional_colors']: additionalColorOptions,
                ['design_machines']: [],
              })
            );
    
            setIsLoading(false);
          });
    }, []);
   
  
    // FOR Typeahead DATA INPUT
    const dropDownChange = (event, stateName) => {
        if (event.length > 0) {
            const selectedValue = event[0].id;
            const selectedValueName = event[0].name;
            setDropdownData(
                (prevstate) => ({
                    ...prevstate,
                    [stateName+'_id']: selectedValue,
                    [stateName + '_name']: selectedValueName,
                })
            );
        }

    }
    // FOR CLIENT STOCKS DATA INPUT
    const clientStocksInputHander = (event) => {
        setJobOrderData(
            { [event.target.name]: event.target.value },
        );
        
    }
    useEffect(()=>{
        const fl = parseFloat(jobOrderData.fl);
        const cir = parseFloat(jobOrderData.cir);
        const qty = parseFloat(jobOrderData.qty);
        setJobOrderData({
            surface_area : parseFloat(fl * cir).toFixed(2),
            total_surface_area : parseFloat(qty * fl * cir).toFixed(2)
        })

    },[jobOrderData.fl,jobOrderData.cir, jobOrderData.qty])

    // FOR CLIENT STOCKS ARRAY READY
    const addOrderDetailsHandler = (event) => {

        let { job_name, receive_date, qty, remarks, total_cylinder_qty,surface_area,total_surface_area,fl,cir,dia } = jobOrderData;
        const {client_id,client_name,printer_id,printer_name} = dropdownData;

        if ( qty && receive_date ) {
            // OBJECT CREATE & PUSH IN AN ARRAY
            let clientStockDetails_arr = [];
            let clientStockDetails_obj = {};
            clientStockDetails_obj.job_name = job_name;
            clientStockDetails_obj.receive_date = receive_date;
            clientStockDetails_obj.qty = qty;
            clientStockDetails_obj.remarks = remarks;
            clientStockDetails_obj.client_name = client_name;
            clientStockDetails_obj.total_cylinder_qty = qty;
            clientStockDetails_obj.client_id = client_id;
            clientStockDetails_obj.printer_id = printer_id;

            clientStockDetails_arr.push(clientStockDetails_obj);
            setClientStockDetails([
                ...clientStockDetails,
                ...clientStockDetails_arr
            ]);
            
        } else {
            if(!jobOrderData.job_name){
            SweetAlert.fire({ title: "Warning", text: "Your Job Order Cyl Qty is 0, Please Select Job No first", icon: "warning" });
            }else if(!receive_date){
                SweetAlert.fire({ title: "Warning", text: "Please enter receieve date",icon: "warning" });
            }else{
                SweetAlert.fire({ title: "Warning", text: "Please all input",icon: "warning" });
            }
        }
    }
    // FOR REMOVE CLIENT STOCKS SINGLE DATA FROM CLIENT STOCKS ARRAY
    const removeClientStockHandler = (jobNo, thisRowQty) => {
        //console.log(supplierId);
        // setJobOrderData({
        //     orderQty: parseInt(jobOrderData.orderQty) - parseInt(thisRowQty)
        // })
        let availableClientStock = clientStockDetails.filter((item) => item.job_name != jobNo);
        // let availableClientStock = clientStockDetails.filter((item) => item.supplier_id != supplierId );
        setClientStockDetails([
            ...availableClientStock
        ]);
    }
    // FINALLY SUBMIT FOR SAVE TO SERVER
    const submitHandler = (data, e) => {
        //data.job_order_id = dropdownData.job_order_id[0].id;
        //data.job_order_id = jobOrderData.job_id;
        //data.receive_date = jobOrderData.receive_date;
        data.totalOrderQty = jobOrderData.qty;
        data.client_stock_details = clientStockDetails;
        data.job_name = jobOrderData.job_name;
        data.fl = jobOrderData.fl;
        data.cir = jobOrderData.cir;
        data.dia = jobOrderData.dia;
        data.surface_area = jobOrderData.surface_area;
        data.total_surface_area = jobOrderData.total_surface_area;
        data.client_id = dropdownData.client_id;
        data.printer_id = dropdownData.printer_id;

        if (jobOrderData.qty ) {
            userPostMethod(UNKNOWN_STOCK_RSURL, data)
                .then(response => {
                    console.log(response);
                    if (response.data.status == 1) {
                        toast.success(response.data.message)
                        clearFormField(e);
                        e.target.reset();
                    } else {
                        toast.error(response.data.message)
                    }
                })
                .catch(error => toast.error(error))
        } else {
            SweetAlert.fire({ title: "Warning", text: "Please order all required cylinder qty!", icon: "warning" });
        }

    }
    const clearFormField = (event) => {
        setJobOrderData({
            'job_order_qty_limit': null,
            'job_name': null,
            'job_no': '',
            'printer_name': null,
            'job_type': null,
            'fl': null,
            'cir': null,
            'total_surface_area': '',
            'dia': '',
            'client_name': null,
            'id': null,
            'entry_date': null,
            'agreement_date': null,
            'total_cylinder_qty': null,
            'client_id': null,
            'printer_id': null,
            'orderQty': 0
        });
        setSelectedValue([]);
        setClientStockDetails([]);
    }

    var menuId = 0;
    if (props.location.state === undefined) {
        var menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Unknown Stock Form</h5>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <PanelRefreshIcons panelRefresh={pageRefreshHandler} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {isLoading ? (<img src={process.env.PUBLIC_URL + '/preloader.gif'} alt="Data Loading" />) :
                                    (
                                        <form onSubmit={handleSubmit(submitHandler)} className="theme-form">
                                            <div className="">

                                                <div className="col-md-5">
                                                    <div className="form-group row">
                                                    <label
                                                        className="col-sm-4 col-form-label required" htmlFor="job_name">
                                                        Job Name</label>
                                                        <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            id="job_name"
                                                            name="job_name"
                                                            value={jobOrderData.job_name}
                                                            onChange={clientStocksInputHander}
                                                            required
                                                            type="text"
                                                            placeholder="Job Name"
                                                            ref={register({
                                                            required: "Job Name Field Required",
                                                            })}
                                                        />
                                                        {errors.job_name && (
                                                            <p className="text-danger">
                                                            {errors.job_name.message}
                                                            </p>
                                                        )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <fieldset className="border" style={{ width: '98%' }}>
                                                    <legend className="w-auto text-left">Unknown Stock</legend>
                                                        <div className="form-row">

                                                            <div className="col-md-3 mb-3">
                                                                <label
                                                                className="col-sm-3 col-form-label required"
                                                                htmlFor="client_id"
                                                                >
                                                                Client
                                                                </label>
                                                                <div className="col-sm-9">
                                                                <Typeahead
                                                                    id="client_id"
                                                                    name="client_id"
                                                                    labelKey={(option) => `${option.name}`}
                                                                    options={typeheadOptions["clients"]|| []}
                                                                    placeholder="Select Client..."
                                                                    onChange={(e) =>
                                                                    dropDownChange(e, "client")
                                                                    }
                                                                    inputProps={{ required: true }}
                                                                    // ref={register({
                                                                    //   required: "client Name Field Required",
                                                                    // })}
                                                                    {...register("client_id")}
                                                                />
                                                                {errors.client_id && (
                                                                    <p className="text-danger">
                                                                    {errors.client_id.message}
                                                                    </p>
                                                                )}
                                                                </div>
                                                            </div>


                                                            <div className="col-md-3 mb-3">
                                                                <label
                                                                className="col-sm-3 col-form-label required"
                                                                htmlFor="printer_id"
                                                                >
                                                                Printer
                                                                </label>
                                                                <div className="col-sm-9">
                                                                <Typeahead
                                                                    id="printer_id"
                                                                    name="printer_id"
                                                                    labelKey={(option) => `${option.name}`}
                                                                    options={typeheadOptions["printers"]|| []}
                                                                    placeholder="Select Printer..."
                                                                    onChange={(e) =>
                                                                    dropDownChange(e, "printer")
                                                                    }
                                                                    inputProps={{ required: true }}
                                                                    // ref={register({
                                                                    //   required: "Printer Name Field Required",
                                                                    // })}
                                                                    {...register("printer_id")}
                                                                />
                                                                {errors.printer_id && (
                                                                    <p className="text-danger">
                                                                    {errors.printer_id.message}
                                                                    </p>
                                                                )}
                                                                </div>
                                                            </div>


                                                            <div className="col-md-2 mb-3">
                                                                <label for="qty">Qty</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="qty"
                                                                    name="qty"
                                                                    type="number"
                                                                    placeholder="quantity"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.qty}
                                                                />
                                                            </div>


                                                            <div className="col-md-1 col-sm-1 mb-3">
                                                                <label for="fl">Fl</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="fl"
                                                                    name="fl"
                                                                    type="text"
                                                                    placeholder="fl"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.fl}
                                                                />
                                                            </div>
                                                            <div className="col-md-1 col-sm-1 mb-3">
                                                                <label for="cir">Cir</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="cir"
                                                                    name="cir"
                                                                    type="text"
                                                                    placeholder="cir"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.cir}
                                                                />
                                                            </div>
                                                            <div className="col-md-1 col-sm-1 mb-3">
                                                                <label for="dia">Dia</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="dia"
                                                                    name="dia"
                                                                    type="text"
                                                                    placeholder="dia"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.dia}
                                                                />
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="form-row" style={{marginLeft:'10px'}}>

                                                            <div className="col-md-3 col-sm-3 mb-3">
                                                                <label for="surface_area">Surface area</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="surface_area"
                                                                    name="surface_area"
                                                                    type="text"
                                                                    placeholder="00"
                                                                    onChange={clientStocksInputHander}
                                                                    // disabled
                                                                    value={jobOrderData.surface_area === "NaN" ? '' : jobOrderData.surface_area}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 col-sm-3 mb-3">
                                                                <label for="total_surface_area">Total Surface area</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="total_surface_area"
                                                                    name="total_surface_area"
                                                                    type="text"
                                                                    // disabled
                                                                    placeholder="00"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.total_surface_area === "NaN" ?'' : jobOrderData.total_surface_area}
                                                                />
                                                            </div>
                                                            
                                                        </div>
                                                        </fieldset>
                                                    </div>
                                                
                                            </div>

                                            <div className="row m-t-10 m-l-10">
                                                <fieldset className="border" style={{ width: '98%' }}>
                                                    <legend className="w-auto text-left">Client Stock</legend>
                                                    <div className="col-md-12">

                                                        <div className="form-row">
                                                            <div className="col-md-2 mb-3">
                                                                <label for="receive_date">Receive Date</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="receive_date"
                                                                    name="receive_date"
                                                                    required = {clientStockDetails.length == 0 ? true : false}
                                                                    type="date"
                                                                    placeholder="Receive Date"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.receive_date ? jobOrderData.receive_date : moment().format('YYYY-MM-DD')}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 mb-3">
                                                                <label for="qty">Qty</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="qty"
                                                                    name="qty"
                                                                    required = {clientStockDetails.length == 0 ? true : false}
                                                                    type="number"
                                                                    placeholder="Qty"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.qty}
                                                                />
                                                            </div>


                                                            <div className="col-md-2 mb-3">
                                                                <label for="remarks">Remarks</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="remarks"
                                                                    name="remarks"
                                                                    type="text"
                                                                    placeholder="Remarks"
                                                                    onChange={clientStocksInputHander}
                                                                    value={jobOrderData.remarks}
                                                                />
                                                            </div>
                                                            <input
                                                                className="form-control"
                                                                id="client_name"
                                                                name="client_name"
                                                                type="hidden"
                                                                onChange={clientStocksInputHander}
                                                                value={jobOrderData.client_name}
                                                            />
                                                            <input
                                                                className="form-control"
                                                                id="id"
                                                                name="id"
                                                                type="hidden"
                                                                onChange={clientStocksInputHander}
                                                                value={jobOrderData.id}
                                                            />
                                                            <input
                                                                className="form-control"
                                                                id="client_id"
                                                                name="client_id"
                                                                type="hidden"
                                                                onChange={clientStocksInputHander}
                                                                value={jobOrderData.client_id}
                                                            />
                                                            <input
                                                                className="form-control"
                                                                id="printer_id"
                                                                name="printer_id"
                                                                type="hidden"
                                                                onChange={clientStocksInputHander}
                                                                value={jobOrderData.printer_id}
                                                            />

                                                            <div className="col-md-1 mb-4 m-t-5">
                                                                <span className="btn btn-primary btn-sm mr-1 m-t-20" type="add" onClick={addOrderDetailsHandler}>Add</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div className="row m-t-10 m-l-10">
                                                <fieldset className="border" style={{ width: '98%' }}>
                                                    <legend className="w-auto text-left">Added Client Stocks</legend>
                                                    <div className="col-md-12">
                                                        <table className="table table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th scope="col" width="15%">Issue To</th> */}
                                                                    {/* <th scope="col" width="20%">Job Ref No</th> */}
                                                                    <th scope="col" width="10%">Job Name</th>
                                                                    <th scope="col" width="20%">Receive Date</th>
                                                                    <th scope="col" width="10%">Qty</th>
                                                                    <th scope="col" width="25%">Remarks</th>
                                                                    <th scope="col" width="10%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    clientStockDetails.length > 0 ?
                                                                        <>
                                                                            {clientStockDetails.map((item, index) =>
                                                                            (
                                                                                <tr key={index}>
                                                                                    <td>{item.job_name}</td>
                                                                                    <td>{item.receive_date}</td>
                                                                                    <td>{item.qty}</td>
                                                                                    <td>{item.remarks}</td>
                                                                                    <td align="center">
                                                                                        <span onClick={() => removeClientStockHandler(item.job_name, item.qty)}>
                                                                                            <i className="icon-close" style={{ width: 25, fontSize: 16, padding: 0, color: '#e4566e', cursor: 'pointer' }}
                                                                                            ></i>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                            )}
                                                                        </>
                                                                        : <tr><td colSpan="6" className="text-center">No data Added</td></tr>
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan="2" align="right">Qty = </td>
                                                                    <td>{jobOrderData.orderQty}</td>
                                                                    <td colSpan="3"></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </fieldset>
                                            </div>

                                            <SubmitButton link="unknownStock/index" menuId={menuId} offset="4" />
                                        </form>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Add;