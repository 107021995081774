import React ,{useEffect, useState , Fragment} from 'react';

import Header from './common/apps/header';
import Footer from './common/apps/footer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tag } from 'react-feather';
import {PreMaster} from '../../api/userUrl';
import Breadcrumb from './common/breadcrumb';
import { userGetMethod } from '../../api/userAction';
import store from '../../store/index';
import DashboardImg from '../../assets/images/welcome-dashboard.png'
import { useHistory } from 'react-router-dom';

const Master = (props) => {
    const [titleMenus,setTitleMenus] = useState('')
    // console.log(this.props.match.params.prefix);
    let prefix = props.match.params.prefix;
    const history = useHistory();
    const name = localStorage.getItem('name') || "User";
    // console.log(props);
    // const [] = useState();
    useEffect(() => {
        userGetMethod(`${PreMaster}?prefix=${prefix}`)
            .then(response => {
                setTitleMenus(response.data.module.module_name);
                store.dispatch({type: 'SET_USER_MENU', payload: response.data.software_menus});
            });
    },[]);
    
    useEffect(()=>{
        if (prefix === 'dashboard') {
            history.push('/dashboard/home');
        }
    },[prefix, history])
    

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="table-responsive col-sm-9 offset-sm-2">
                                    <h1>Hi {name}</h1>
                                    <h2>Welcome to {titleMenus}</h2>
                                    <hr/>
                                    <div className='card border-0 d-flex justify-content-center align-items-center'>
                                    <img src ={DashboardImg} style={{ width: '60%'}} class="img-fluid" alt="Responsive image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) =>{
    return {
        moduleMenu: state.userMenu.userMenus
    };
}
export default connect(mapStateToProps)(Master);
// export default Master;