import React, { Fragment, useEffect, useState } from 'react';
import useForm from "react-hook-form";
import { SubmitButton } from '../../../common/GlobalButton';

const Form = (props) => {
    const { handleSubmit, register, errors } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [cylTypeCheckbox, setCylTypeCheckbox] = useState('0'); // Default to "No"

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth is zero-indexed
    const currentYear = currentDate.getFullYear();

    let menuId = props.location?.state?.params?.menuId || 0;

    const submitHandler = (data, e) => {
        var url = `${process.env.PUBLIC_URL}/monthlyJobFlow/report/${data.month}/${data.year}/${data.cylinder_type || null}`;
        console.log({url});
        window.open(url, '_blank', 'height=800,width=1200');
    };
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Monthly Job Flow Form</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(submitHandler)} className="needs-validation theme-form">
                                    
                                    <div className="form-group row">
                                        <label className="col-sm-1 col-form-label" htmlFor="month">Month</label>
                                        <div className="col-sm-2">
                                            <select className="form-control" name="month" defaultValue={currentMonth} ref={register({})}>
                                                <option value="">Select One</option>
                                                {[...Array(12)].map((_, i) => (
                                                    <option key={i+1} value={i+1}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            {errors.month && <p className='text-danger'>{errors.month.message}</p>}
                                        </div>
                                        
                                        <label className="col-sm-1 col-form-label" htmlFor="year">Year</label>
                                        <div className="col-sm-2">
                                            <select className="form-control" name="year" defaultValue={currentYear} ref={register({})}>
                                                <option value="">Select One</option>
                                                {Array.from({ length: 16 }, (_, i) => (
                                                    <option key={i} value={2015 + i}>{2015 + i}</option>
                                                ))}
                                            </select>
                                            {errors.year && <p className='text-danger'>{errors.year.message}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Cylinder Type</label>
                                        <div className="col-sm-1 mt-2">
                                            <input 
                                                onChange={(e) => setCylTypeCheckbox(e.target.value)}
                                                name="cyl_type_checkbox" 
                                                type="radio"
                                                value="1"
                                                checked={cylTypeCheckbox === '1'}
                                            /> Yes
                                        </div>
                                        <div className="col-sm-2 mt-2">
                                            <input 
                                                onChange={(e) => setCylTypeCheckbox(e.target.value)}
                                                name="cyl_type_checkbox" 
                                                type="radio"
                                                value="0"
                                                checked={cylTypeCheckbox === '0'}
                                            /> No
                                        </div>
                                        {cylTypeCheckbox === '1' && (
                                            <div className="col-sm-2">
                                                <select className="form-control" name="cylinder_type" ref={register({})}>
                                                    <option value="">Select Type</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <SubmitButton link="#" offset="2" menuId={menuId} />

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Form;
