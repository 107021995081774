import React, { Fragment, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { DIG_SHIFT_RSURL, ShiftFor, digShiftFor, userHasAccess } from '../../../../api/userUrl';
import { userGetMethod, userDeleteMethod } from '../../../../api/userAction';
import Pagination from "react-js-pagination";
import { AddButton } from '../../common/GlobalButton';
import { Button } from 'react-bootstrap';
import { EditButton } from '../../../common/GlobalButton';

export default function ListData(props) {
    const [digShiftData, setDigShiftData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const [hasAccess, setHasAccess] = useState({});
    const [accLoad, setAccLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState();
    const [perPage, setPerPage] = useState(25);
    const [totalData, setTotalData] = useState(0);
    const [ascDesc, setAscDesc] = useState(false);
    const [shift_for,setShift_for] = useState(2)
    const [runningStatus,setRunningStatus] = useState(1)
    const [shiftExist,setShiftExist] = useState(0)

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }

    useEffect(() => {
        // ADD,EDIT,DELETE,SHOW ACCESS CHECK
        userGetMethod(`${userHasAccess}/${menuId}`)
            .then(response => {
                setHasAccess(response.data);
                setAccLoad(false);
            });

            userGetMethod(`${ShiftFor}`)
            .then(response => {
                setOptions(response.data.shiftNames);
            })
            .catch(error => {
                console.log(error);
            });
           
        pageChange();
    },[]);

    useEffect(()=>{
        userGetMethod(`${DIG_SHIFT_RSURL}?shift_for=${shift_for}&running_status=${runningStatus}&perPage=${perPage}&searchText=${searchText}`)
            .then(response => {
                setCurrentPage(response.data.shiftDetails.current_page)
                setPerPage(response.data.shiftDetails.per_page)
                setTotalData(response.data.shiftDetails.total)
                setDigShiftData(response.data.shiftDetails.data)
                setShiftExist(response.data.shiftExist)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    },[shift_for,perPage,runningStatus])



    const handleSearchText = (e) => {
        setSearchText(e);
    }
    const searchHandler = (e) => {
        setIsLoading(true);
        userGetMethod(`${DIG_SHIFT_RSURL}?shift_for=${shift_for}&running_status=${runningStatus}&page=${1}&perPage=${perPage}&searchText=${searchText}`)
        .then(response => {
            setDigShiftData(response.data.shiftDetails.data)
            setCurrentPage(response.data.shiftDetails.current_page)
                setPerPage(response.data.shiftDetails.per_page)
                setTotalData(response.data.shiftDetails.total)
                setShiftExist(response.data.shiftExist)
            setIsLoading(false);
        })
        .catch(error => console.log(error)); 
    }

    const deleteHandler = (itemId, deleteLink) => {
        // const getConfirmation = () => {
            
        // }
        
        userDeleteMethod(deleteLink, itemId)
            .then(response => {
                if (response.data.status == 1) {
                    setIsLoading(true);
                    let newData = digShiftData.filter(data => data.id != itemId);
                    setDigShiftData(newData);
                    setIsLoading(false);
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => toast.error(error));
    }

    const pageChange = (pageNumber = 1) => {
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(`${DIG_SHIFT_RSURL}?shift_for=${shift_for}&running_status=${runningStatus}&page=${pageNumber}&perPage=${perPage}&searchText=${searchText}`)
            .then(response => {
                setCurrentPage(response.data.shiftDetails.current_page)
                setPerPage(response.data.shiftDetails.per_page)
                setTotalData(response.data.shiftDetails.total)
                setShiftExist(response.data.shiftExist)
                setDigShiftData(response.data.shiftDetails.data)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }

    const perPageBoxChange = (e) => {
        let paramValue = e.target.value;
        let paramName = e.target.name;
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(`${DIG_SHIFT_RSURL}?shift_for=${shift_for}&running_status=${runningStatus}&perPage=${perPage}&searchText=${searchText}`)
            .then(response => {
                setCurrentPage(response.data.shiftDetails.current_page)
                setPerPage(response.data.shiftDetails.per_page)
                setTotalData(response.data.shiftDetails.total)
                setShiftExist(response.data.shiftExist)
                setDigShiftData(response.data.shiftDetails.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    }

    const sortHandler = (params) => {
        setAscDesc(!ascDesc);
        let ascUrl = '';
        if (ascDesc === true) {
            ascUrl = `${DIG_SHIFT_RSURL}?asc=${params}&desc=`;
        } else {
            ascUrl = `${DIG_SHIFT_RSURL}?asc=&desc=${params}`;
        }
        
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascUrl)
            .then(response => {
                setCurrentPage(response.data.platingShifts.current_page)
                setPerPage(response.data.platingShifts.per_page)
                setTotalData(response.data.platingShifts.total)
                setShiftExist(response.data.shiftExist)
                setDigShiftData(response.data.platingShifts.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    }

    return (
        
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Dig Shift List</h5>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <PanelRefreshIcons panelRefresh={pageChange} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3">
                                    <div className="input-group text-box searchBox">
                                        <input
                                            type="text"
                                            className="form-control input-txt-bx"
                                            placeholder="Type to Search..."
                                            onChange={(e) => handleSearchText(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <button 
                                                className="btn btn-primary btn-sm" 
                                                type="button" 
                                                onClick={searchHandler} 
                                            >Go
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="custom-table-pagination m-r-10">
                                        <label className="mt-3">
                                            <span>

                                                <select className='form-control' value={shift_for} disabled onChange={(e) => setShift_for(e.target.value)}>
                                                            <option value="0"> All</option>
                                                            {options.map((option, index) => (
                                                                <option key={index} value={option.id}>
                                                                    {option.shift_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4" style={{textAlign:'right'}}>
                                    <div className="custom-table-pagination m-r-10">
                                        <label className="mt-3">
                                            <span>

                                                <select className='form-control' value={runningStatus} onChange={(e) => setRunningStatus(e.target.value)}>
                                                            <option value="2"> All</option>
                                                            <option value="1"> Active</option>
                                                            <option value="0"> Inactive</option>
                                                            
                                                        </select>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-2 text-right">
                                    <Button style={{padding:"5px 10px 5px 10px",marginTop:'10px',marginRight:"10px"}}> {shiftExist.running_status !== 1 ? (<Link to={{
                                    pathname: `${process.env.PUBLIC_URL}/shiftControl/add`,
                                    state: { params: {menuId: shift_for} }}}
                                className="btn btn-primary">ADD</Link>) : "Created"}
                                </Button>
                                    
                                    {/* <PerPageBox pageBoxChange={perPageBoxChange}/> */}
                                </div>
                            </div>
                            
                            <div className="card-body datatable-react">
                                {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="10%"> SL.</th>
                                                    <th scope="col" width="20%"> Date</th>
                                                    <th scope="col" width="40%"> Shift Name</th>
                                                    <th scope="col" width="10%"> Status</th>
                                                    {/* <th scope="col" width="15%" onClick={() => sortHandler(4)} ><i className="fa fa-sort"></i> Shifting</th> */}
                                                    <th scope="col" width="15%">Shifting</th>
                                                    <th scope="col" width="5%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    digShiftData.length > 0 ? 
                                                        <>
                                                            {digShiftData.map((item, index) =>           
                                                                (
                                                                    <tr key={index}>
                                                                        <td scope="row">{ ((index+1) + (currentPage == 1 ? 0 : (currentPage*perPage - perPage))) }</td>
                                                                        <td>{item.shift_date}</td>
                                                                        <td>{item.shift_name}</td>
                                                                        <td>{item.running_status == 0 ? "Inactive" : 'Active'}</td>
                                                                        <td>
                                                                            {item.shift_type == 1 ? 'Day' : (item.shift_type == 2 ? 'Evening' : 'Night')}
                                                                        </td>
                                                                        <td className="">

                                                                            <EditButton link={`/digShift/${item.id}`}></EditButton>
                                                                            {/* { accLoad === false ? 
                                                                                <>
                                                                                    {hasAccess.edit === true ? <EditButton link={`/digShift/${item.id}`} menuId={ menuId } /> : ''} 
                                                                                   
                                                                                </> : ''
                                                                            } */}
                                                                        </td>
                                                                    </tr>
                                                                )                
                                                            )}
                                                        </> 
                                                    : <tr><td colSpan="5" className="text-center">No data found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination 
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={totalData}
                                    onChange={pageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText="First"
                                    lastPageText="Last"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}