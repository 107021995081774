/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState ,Fragment } from 'react';
import logo from '../../../../assets/images/logo.png';
import UserMenu from './userMenu';
import UserModules from './userModules';
import Notification from './notification';
import SearchHeader from './searchHeader';
import { Link } from 'react-router-dom';
import { AlignLeft, Maximize, Bell, MoreHorizontal } from 'react-feather';
import Report from './Report';
import { FaSearch } from "react-icons/fa";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const pathSegments = window.location.pathname;
  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open'); 
    }
  }

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.add('show');
    } else {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.remove('show');
    }
  }

  //full screen function
  function goFull() {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { 
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  }

  return (
    <Fragment>
      <div className={`page-main-header ${pathSegments.includes('dashboard') ? 'open' : ''}`} >
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/dashboard/default">
                <img className="img-fluid" src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block cursor-pointer">
            <div className="media-body text-right switch-sm cursor-pointer">
              <label className="switch cursor-pointer">
                <a onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li>
				          <Link to={`${process.env.PUBLIC_URL}/dashboard/apps`}>Home</Link>
              </li>
              <li style={{width:'220px'}}>
				          <Report />
              </li>
              <li  className="dropdown" style={{display:'flex', alignItems: 'center'}}>
              <FaSearch  style={{marginTop:'0px',marginRight:'7px', width:'15px'}}/>
              <Link to={`${process.env.PUBLIC_URL}/jobSearch`}>Job Search</Link>
              </li>
              {/* <li  className="dropdown">
              <Link to={`${process.env.PUBLIC_URL}/jobSearch`}><div className="d-flex align-items-center">
              <FaSearch  style={{marginTop:'0px',marginRight:'7px', width:'15px'}}/>
              <div>Job Search</div>
              </div></Link>
              </li> */}
              
              {/* add dropdown to this  */}
              {/* report api https://del.uddoktahub.com/api/user/software-menus/3 */}
              <li>
                {/* <SearchHeader /> */}
              </li>
              <li>
                <a onClick={goFull} className="text-dark cursor-pointer" >
                  <Maximize />
                </a>
              </li>
              {/* <li className="onhover-dropdown">
                <Notification />
                <Bell />
                <span className="dot"></span>
                <Notification />
              </li> */}
              {/* <UserModules /> */}
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal/></div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div>
          </script>
        </div>
      </div>
    </Fragment>
  )
};
export default Header;